var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "dataBaseUpload",
      attrs: {
        title: "图片上传",
        "mask-closable": false,
        draggable: false,
        width: "900",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Upload",
        {
          ref: "uploadImage",
          attrs: {
            type: "drag",
            name: "file",
            accept: "image/png, image/jpg, image/jpeg",
            format: _vm.fileTypeArr,
            "on-success": _vm.uploadSuccess,
            data: _vm.data,
            action: _vm.api,
            headers: _vm.upHeaders,
            "before-upload": _vm.handleUpload,
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "20px 0" } },
            [
              _c("Icon", {
                staticStyle: { color: "#3399ff" },
                attrs: { type: "ios-cloud-upload", size: "52" },
              }),
              _c("p", [_vm._v("请选择上传的文件")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            color: "red",
            "font-weight": "bold",
            "margin-top": "10px",
          },
        },
        [
          _c("p", [_vm._v("提示：")]),
          _c("p", [_vm._v("1.请选择图片文件")]),
          _c("p", [_vm._v('2.上传文件类型只能为"png，jpg，jpeg"')]),
        ]
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }